import React from 'react';

function TimeSelector({ onSelectTime, initialTime, selectedTime }) {
  const timeSlots = ['16:00', '17:00', '18:00', '19:00', '20:00', '21:00'];

  return (
    <div className="grid grid-cols-3 gap-2 justify-items-center">
      {timeSlots.map((time) => (
        <button
          key={time}
          onClick={() => onSelectTime(time)}
          className={`w-[80px] h-[40px] text-sm font-medium rounded-[20px] transition-colors
            ${selectedTime === time ? 'bg-[#4ABC85] text-white' : 'bg-[#EEEEEE] text-black hover:bg-[#4ABC85] hover:text-white'}`}
        >
          {time}
        </button>
      ))}
    </div>
  );
}

export default TimeSelector;
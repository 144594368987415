import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

function Calendar({ onSelectDate, initialDate, selectedDate }) {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    if (initialDate) {
      setCurrentDate(new Date(initialDate));
    }
  }, [initialDate]);

  const [selectedDay, setSelectedDay] = useState(null);

  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const weekdays = ['일', '월', '화', '수', '목', '금', '토'];

  const months = Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('ko-KR', { month: 'long' }));

  const handleMonthChange = (e) => {
    const newMonth = parseInt(e.target.value);
    setCurrentDate(new Date(currentDate.getFullYear(), newMonth, 1));
    setSelectedDay(null);
  };

  const handleDateClick = (day) => {
    const selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    setSelectedDay(day);
    onSelectDate(selectedDate);
  };

  return (
    <div className="w-full mb-20">
      <div className="flex justify-between items-center mb-4">
        <div className="relative">
          <select
            value={currentDate.getMonth()}
            onChange={handleMonthChange}
            className="w-[130px] h-[40px] appearance-none bg-[#EEEEEE] rounded-2xl py-2 px-4 pr-8 text-sm font-bold"
          >
            {months.map((month, index) => (
              <option key={month} value={index}>
                {currentDate.getFullYear()} {month}
              </option>
            ))}
          </select>
          <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#A3A1A2]" size={20} />
        </div>
      </div>
      <div className="grid grid-cols-7 gap-1">
        {weekdays.map(day => (
          <div key={day} className="font-semibold text-sm mb-2 text-center">{day}</div>
        ))}
        {Array(firstDayOfMonth).fill(null).map((_, index) => (
          <div key={`empty-${index}`} />
        ))}
        {days.map(day => (
          <div key={day} className="flex justify-center items-center">
            <button
              onClick={() => handleDateClick(day)}
              className={`w-10 h-10 flex items-center justify-center rounded-full text-sm
                ${selectedDate && selectedDate.getDate() === day && selectedDate.getMonth() === currentDate.getMonth() 
                  ? 'bg-[#4ABC85] text-white' 
                  : 'hover:bg-gray-100'}`}
            >
              {day}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Calendar;
import React, { useState } from 'react';
import { Check } from 'lucide-react';

const PrivacyPolicyForm = ({ onAgree }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleAgree = () => {
    if (isChecked) {
      onAgree();
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-lg font-bold text-center">개인정보 수집 및 이용 동의</h2>

      <div className="space-y-4 text-sm">
        <h3 className="text-center font-semibold">개인정보 수집 및 이용에 대한 안내</h3>
        <p>학원 입학 및 교육서비스 제공·안내 등을 위하여 본인(학부모)의 개인정보를 학원이 수집·이용하거나 제3자에게 제공하고자 하는 경우에는 [개인정보 보호법] 제15조 제1항 제1호, 제17조 제1항 제1호, 제22조 제3항, 제5항, 제24조 제1항 제1호에 따라 본인(학부모)의 동의를 얻어야 합니다. 이에 본인(학부모)은 학원이 아래의 내용과 같이 본인(학부모)의 개인정보를 수집이용 또는 제공하는 것에 동의합니다.</p>

        <h4 className="font-semibold">수집·이용 목적</h4>
        <ul className="list-disc pl-5">
          <li>학원입학</li>
          <li>마케팅 및 광고 활용</li>
          <li>스카이(SKY)미래연 수학학원 온라인 및 오프라인 교육서비스 제공</li>
          <li>CCTV를 설치하여 사고예방과 시설물 보호 및 학원생들의 면학 분위기 조성을 위한 학습상태를 관리</li>
        </ul>

        <h4 className="font-semibold">수집·이용할 항목</h4>
        <ul className="list-disc pl-5">
          <li>개인식별정보 : 학생성명, 생년월일, 가상계좌번호, 학교, 학년, 집전화번호, 휴대전화번호, 집주소, 이메일주소, 성적표, FAX번호, 법정대리인정보 (만14세미만의 경우), 교육서비스이용기록, 수강료납부기록, 국세청 현금영수증 발급요청 정보 (학부모 휴대전화번호 또는 현금영수증카드번호 사업자등록번호), 수강료반환정보(학부모성명, 은행계좌번호)</li>
          <li>CCTV 촬영정보</li>
        </ul>

        <h4 className="font-semibold">보유·이용 기간</h4>
        <p>수집된 개인정보는 해당일로부터 최대 3년간 보관하며 수집·이용 및 제공목적이 달성된 경우[개인정보 보호법] 제 21조에 따라 처리합니다. 단, 학원 수업중단 후에는 내부방침 및 위에기재된 목적과 관련된 사고 조사, 분쟁 해결, 민원처리, 법령상 의무이행을 위하여 필요한범위 내에서만 보유·이용됩니다.</p>

        <h4 className="font-semibold">동의를 거부할 권리 및 동의를 거부할 경우의 불이익</h4>
        <p>위 개인정보의 제공에 대한 동의를 거부할 수 있으며, 동의 후에도 언제든지 철회 가능합니다. 다만, 귀하가 동의하지 않은 경우 회원가입 및 제공 목적과 관련된 교육서비스안내 등 편의는 제공받을 수 없습니다.</p>
      </div>

      <div className="flex items-start space-x-2">
        <div
          onClick={() => setIsChecked(!isChecked)}
          className={`flex-shrink-0 w-5 h-5 border rounded flex items-center justify-center cursor-pointer ${
            isChecked ? 'bg-[#4ABC85] border-[#4ABC85]' : 'border-gray-300'
          }`}
          style={{ minWidth: '20px', minHeight: '20px' }}
        >
          {isChecked && <Check size={16} color="white" />}
        </div>
        <label className="text-sm text-gray-600 cursor-pointer" onClick={() => setIsChecked(!isChecked)}>
          [개인정보 보호법] 제15조 및 제17조에 따라 위 내용으로 개인정보를 수집·이용 및 제공하는데 동의합니다.
        </label>
      </div>

      <button
        onClick={handleAgree}
        disabled={!isChecked}
        className={`w-full h-[60px] bg-[#4ABC85] text-white rounded-2xl font-bold text-lg ${
          !isChecked ? 'opacity-40 cursor-not-allowed' : ''
        }`}
      >
        가입하기
      </button>
    </div>
  );
};

export default PrivacyPolicyForm;
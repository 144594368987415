import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignInPage from './pages/SignInPage';
import SignUpForm from './components/SignUpForm';
import SignUpCompletePage from './pages/SignUpCompletePage';
import SchedulePage from './pages/SchedulePage';
import BookSchedulePage from './pages/BookSchedulePage';
import ResultPage from './pages/ResultPage';
import FindCredentials from './components/FindCredentials';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpForm />} />
        <Route path="/signup-complete" element={<SignUpCompletePage />} />
        <Route path="/schedule" element={<SchedulePage />} />
        <Route path="/schedule/book" element={<BookSchedulePage />} />
        <Route path="/result" element={<ResultPage />} />
        <Route path="/find-credentials" element={<FindCredentials />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState } from 'react';
import { Bell, User } from 'lucide-react';
import logoHeader from '../assets/logo_header.png'; // 이미지 경로를 적절히 조정하세요
import ProfileModal from './ProfileModal';

function Header() {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const toggleProfileModal = () => {
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  return (
    <header className="flex justify-between items-center p-4 bg-white">
      <div className="px-4"> {/* 로고를 감싸는 div에 패딩 추가 */}
        <img src={logoHeader} alt="로고" className="h-8" />
      </div>
      <div className="flex items-center space-x-4 px-4"> {/* 아이콘을 감싸는 div에 패딩 추가 */}
        <Bell className="w-6 h-6 text-gray-600" />
        <User className="w-6 h-6 text-gray-600 cursor-pointer" onClick={toggleProfileModal} />
      </div>
      <ProfileModal isOpen={isProfileModalOpen} onClose={toggleProfileModal} />
    </header>
  );
}

export default Header;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Calendar from '../components/Calendar';
import TimeSelector from '../components/TimeSelector';
import { ChevronLeft } from 'lucide-react';

function BookSchedulePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [existingBooking, setExistingBooking] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (location.state && location.state.bookedDate && location.state.bookedTime) {
      const bookedDate = new Date(location.state.bookedDate);
      setExistingBooking({
        date: bookedDate,
        time: location.state.bookedTime
      });
      setSelectedDate(bookedDate);
      setSelectedTime(location.state.bookedTime);
    }
  }, [location.state]);

  useEffect(() => {
    if (existingBooking) {
      setIsChanged(
        selectedDate.getTime() !== existingBooking.date.getTime() ||
        selectedTime !== existingBooking.time
      );
    }
  }, [selectedDate, selectedTime, existingBooking]);

  const handleBook = () => {
    if (selectedDate && selectedTime) {
      navigate('/schedule', { 
        state: { 
          bookedDate: selectedDate, 
          bookedTime: selectedTime 
        } 
      });
    }
  };

  const handleCancel = () => {
    navigate('/schedule', { state: null });
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <main className="flex-grow flex flex-col items-center justify-start p-4 pt-6">
        <div className="w-full max-w-md">
          <div className="flex items-center mb-6">
            <button onClick={() => navigate(-1)} className="mr-2">
              <ChevronLeft size={24} />
            </button>
            <h1 className="text-lg font-semibold">KY TEST 예약하기</h1>
          </div>
          <Calendar 
            onSelectDate={setSelectedDate} 
            initialDate={existingBooking?.date} 
            selectedDate={selectedDate}
          />
          {selectedDate && (
            <>
              <p className="text-sm font-bold mt-20 mb-5">
                {selectedDate.toLocaleString('ko-KR', { month: 'long', day: 'numeric' })}
              </p>
              <TimeSelector 
                onSelectTime={setSelectedTime} 
                initialTime={existingBooking?.time}
                selectedTime={selectedTime}
              />
            </>
          )}
          {existingBooking ? (
            <div className="flex justify-between mt-[100px]">
              <button
                onClick={handleCancel}
                className="w-[48%] h-[60px] bg-[#F9344B] text-white rounded-2xl font-bold text-lg"
              >
                예약 취소
              </button>
              <button
                onClick={handleBook}
                className={`w-[48%] h-[60px] text-white rounded-2xl font-bold text-lg ${
                  isChanged ? 'bg-[#4ABC85]' : 'bg-[#4ABC85] opacity-10 cursor-not-allowed'
                }`}
                disabled={!isChanged}
              >
                예약 변경
              </button>
            </div>
          ) : (
            <button
              onClick={handleBook}
              className="w-full h-[60px] bg-[#4ABC85] text-white rounded-2xl font-bold text-lg mt-[100px]"
              disabled={!selectedDate || !selectedTime}
            >
              예약하기
            </button>
          )}
        </div>
      </main>
    </div>
  );
}

export default BookSchedulePage;
import React from 'react';
import { Share, CreditCard } from 'lucide-react';
import Header from '../components/Header';

function ResultPage() {
  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <main className="flex-grow flex flex-col items-center justify-center p-4">
        <div className="w-full max-w-md flex justify-between space-x-4">
          <button className="w-[130px] h-[60px] flex items-center justify-center space-x-2 bg-[#EEEEEE] text-[#7B7979] rounded-lg">
            <Share size={20} />
            <span className="font-bold text-sm">공유하기</span>
          </button>
          <button className="w-[180px] h-[60px] flex items-center justify-center space-x-2 bg-[#4ABC85] text-white rounded-lg">
            <CreditCard size={20} />
            <span className="font-bold text-sm">수강 등록하기</span>
          </button>
        </div>
      </main>
    </div>
  );
}

export default ResultPage;
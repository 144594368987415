import React, { useState, useEffect } from 'react';
import { X, AlertCircle } from 'lucide-react';

const AuthenticationModal = ({ isOpen, onClose, type, onSuccess }) => {
  const [code, setCode] = useState('');
  const [timer, setTimer] = useState(180); // 3 minutes
  const [error, setError] = useState('');

  useEffect(() => {
    let interval;
    if (isOpen && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isOpen, timer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleResend = () => {
    setTimer(180);
    setError('');
    setCode('');
    // 여기에 재전송 로직 추가
  };

  const handleAuthenticate = () => {
    if (code.length !== 6) {
      setError('올바른 인증 코드를 입력해주세요.');
      return;
    }
    // 여기에 인증 로직 추가
    // 인증 성공 시:
    onSuccess();
    onClose();
  };

  const handleCodeChange = (e) => {
    const value = e.target.value;
    setCode(value);
    if (value.length === 0) {
      setError('');
    } else if (value.length === 6) {
      setError('');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={onClose}>
      <div className="bg-white p-6 rounded-2xl w-[72%] max-w-sm" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">{type === 'email' ? '이메일 인증' : '전화번호 인증'}</h2>
          <X onClick={onClose} className="cursor-pointer" />
        </div>
        <p className="text-center mb-4">
          {type === 'email' ? '이메일로 전송된' : '전화번호로 전송된'}
          <br />코드 6자리를 입력해주세요
        </p>
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <label htmlFor="authCode" className="block text-sm font-normal text-[#231F20]">인증 코드</label>
            {error && (
              <span className="text-xs text-[#F9344B] flex items-center">
                <AlertCircle size={12} className="mr-1" />
                {error}
              </span>
            )}
          </div>
          <div className="relative">
            <input
              id="authCode"
              type="text"
              value={code}
              onChange={handleCodeChange}
              maxLength={6}
              className={`w-full h-[60px] px-3 bg-[#EEEEEE] border rounded-2xl text-sm focus:outline-none focus:ring-1 placeholder-gray-400 caret-[#4ABC85] ${
                error ? 'border-[#F9344B] bg-[#F9344B] bg-opacity-10 focus:border-[#F9344B] focus:ring-[#F9344B]' : 'border-gray-300 focus:border-[#4ABC85] focus:bg-opacity-10 focus:bg-[#4ABC85] focus:ring-[#4ABC85]'
              }`}
              placeholder="인증 코드 입력"
            />
            <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-500">
              {formatTime(timer)}
            </span>
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <button
            onClick={handleResend}
            className="w-[130px] h-[60px] bg-[#EEEEEE] text-[#A3A1A2] rounded-xl"
          >
            다시보내기
          </button>
          <button
            onClick={handleAuthenticate}
            className="w-[130px] h-[60px] bg-[#4ABC85] text-white rounded-xl"
          >
            인증하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationModal;